import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';

const WorkflowItems = workflowitem => {

    return (
        <>
            <Card className='rounded' style={{ height: '100%', width: '100%' }} >
                <Card.Body>
                    <Card.Title as='h4'>
                        <strong>{workflowitem.workflowitem.originator}</strong>
                    </Card.Title>
                    <Card.Text as='div'>
                        <div className='my-3 text-warning'>
                            Originated: {workflowitem.workflowitem.startDate}
                        </div>
                    </Card.Text>

                    <Card.Text as="div">
                        <div className='text-dark'>{workflowitem.workflowitem.status}</div>
                    </Card.Text>

                </Card.Body>
            </Card>
        </>
    )
}

export default WorkflowItems