import React from 'react'
import { useState } from 'react'

const FormInput = (props) => {
    const [focused, setFocused ] = useState(false);

    const {label, errorMessage, onChange, id, ...inputProps } = props;

    const handleFocus = (e) => {
        setFocused(true);
    }

return (
    <div className='d-flex flex-column'>
        <label className='w-50 mr-3 py-3 fs-3 rounded'>{label}</label>
        <textarea className='w-100 mr-3 p-3 rounded' style={{ height: '150px' }} {...inputProps} onChange={onChange} onBlur={handleFocus} focused={focused.toString()} />
    </div>
)
}

export default FormInput