import React from 'react'
import { useState, useRef } from 'react'
import FormInput from './FormInput'
import FormInputTextarea from './FormInputTextarea'
import reCAPTCHA from "react-google-recaptcha"

const ContactUsForm = () => {
  const captchaRef = useRef(null)

  const [values, setVales] = useState({
    name: '',
    email: '',
    telephone: '',
    message: ''

  })

  const inputs = [
    {
      id: 1,
      name: "name",
      type: "text",
      placeholder: "name",
      errorMessage: "Please add a name.",
      label: "Name",
      required: true
    },
    {
      id: 2,
      name: "email",
      type: "email",
      placeholder: "email",
      errorMessage: "Please add a valid email address.",
      label: "Email",
      required: true
    },
    {
      id: 3,
      name: "telephone",
      type: "text",
      placeholder: "telephone (optional)",
      errorMessage: "Unknown input, please check your number.",
      label: "Telephone"
    },
    {
      id: 4,
      name: "message",
      type: "textarea",
      placeholder: "message",
      errorMessage: "Please include a message about what you're interested in.",
      label: "Message",
      required: true
    }
  ]

  const handleSubmit = (e) => {
    e.preventDefault();
    const uri = 'https://hce3j044hk.execute-api.us-west-1.amazonaws.com/default/precisionfluidcontrolsContactUsEmail';

    fetch(uri, {
      method: "POST",
      body: JSON.stringify(values)
    }).then((response) => {
      alert("Thank you! We will contact you in 1-2 business days.");
      return response.json();
    });

    setVales({
      name: "",
      email: "",
      telephone: "",
      message: ""
    })
  }

  const onChange = (e) => {
    setVales({ ...values, [e.target.name]: e.target.value })
  }

  return (
    <>
      <form className='w-100' id='form' onSubmit={handleSubmit} onChange={onChange}>
        {inputs.map((input) => (<>
          {input.type === "textarea" ? <FormInputTextarea key={input.id} {...input} value={values[input.name]} onChange={onChange} /> 
          : <FormInput key={input.id} {...input} value={values[input.name]} onChange={onChange} />}
          </>
        ))}
        
        {/* <reCAPTCHA
          sitekey="your-site-key"
          ref={captchaRef}
        /> */}
        <button type="submit" className="btn btn-primary my-3">Submit</button >
      </form>
    </>
  )
}

export default ContactUsForm