import React from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'

const Jobs = ({ job }) => {
    return (
        <>
            <Card className='rounded' style={{ height: '100%', width: 'auto' }} >
                <Link to={`/contactus`}>
                </Link>

                <Card.Body>
                    <Link to={`/contactus`}>
                        <Card.Title as='div'>
                            <strong>{job.name}</strong>
                        </Card.Title>
                    </Link> 
                    <Card.Text as='div'>
                        <div className='my-3 text-warning'>
                            {job.department}
                        </div>
                    </Card.Text>

                    <Card.Text as="div">
                        <div className='text-dark'>{job.description}</div>
                    </Card.Text>

                </Card.Body>
            </Card>
            </>
    )
}

export default Jobs