import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap';
import { Navigate, Link, useRoutes, useLocation } from 'react-router-dom';
import Section1 from '../components/workflow/section1';
import Section2 from '../components/workflow/section2';
import Section3 from '../components/workflow/section3';
import Section4 from '../components/workflow/section4';
import Section5 from '../components/workflow/section5';
import Section6 from '../components/workflow/section6';
import Section7 from '../components/workflow/section7';
import Section8 from '../components/workflow/section8';

// class WorkflowitemScreen extends Component {
const WorkflowitemScreen = () => {
    const [authenticated] = useState(sessionStorage.getItem("token"));
    const location = useLocation();

    if (!authenticated) {
        return (
            <Navigate replace to="/login" />
        )
    } else {
        const data = location.state.workflow;
        return (
            <>
                <Container>
                    <div className='w-100'>
                        <h2><Link to={`/workflows`}>Back...</Link></h2>

                        <h3 style={{ color: 'white' }}>Current Status: <p style={{ color: 'red' }}>{data ? data.startDate : 'Error with data!'}</p></h3>
                        <section className='panel'>
                            <h2 className="p-3">Section 1: Component Information</h2>
                            <Section1 workflowitem={data.componentInformation}
                                isReadOnly={data.status === "Component Information" ? false : true}>
                            </Section1>
                        </section>

                        <h2 className="p-3">Section 2: Engineering Configuration Management</h2>
                        {/* <Section2 workflowitem={workflowitem.engineeringConfigurationManagement}
                            isReadOnly={workflowitem.status === "Engineering Configuration Management" ? false : true}></Section2>
                        <h2 className="p-3">Section 3: Clear Design Intent</h2>
                        <Section3 workflowitem={workflowitem.clearDesignIntent} isReadOnly={workflowitem.status === "Section 3: Clear Design Intent" ? false : true}></Section3>
                        <h2 className="p-3">Section 4: Design Requirements</h2>
                        <Section4 workflowitem={workflowitem.designRequirements} isReadOnly={workflowitem.status === "designRequirements" ? false : true}></Section4>
                        <h2 className="p-3">Section 5: Inspection Drawing</h2>
                        <Section5 workflowitem={workflowitem.inspectionDrawing} isReadOnly={workflowitem.status === "inspectionDrawing" ? false : true}></Section5>
                        <h2 className="p-3">Section 6: Manufacturing Planning</h2>
                        <Section6 workflowitem={workflowitem.manufacturingPlanning} isReadOnly={workflowitem.status === "manufacturingPlanning" ? false : true}></Section6>
                        <h2 className="p-3">Section 7: Inspection Forms</h2>
                        <Section7 workflowitem={workflowitem.inspectionForms} isReadOnly={workflowitem.status === "inspectionForms" ? false : true}></Section7>
                        <h2 className="p-3">Section 8: Closeout</h2>
                        <Section8 workflowitem={workflowitem.closeout} isReadOnly={workflowitem.status === "closeout" ? false : true}></Section8> */}
                    </div>
                </Container>

            </>
        )
    }
}

export default WorkflowitemScreen