import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';

const CareersManagement = () => {

    // useEffect(() => {
    //     fetch(`http://10.0.1.12:8080/createWorkflow`)
    //         .then((response) => {
    //             if (!response.ok) {
    //                 setError(`Response error: ${response.status}`);
    //                 throw new Error(
    //                     `This is an HTTP error: The status is ${response.status}`
    //                 );
    //             }
    //             return response.json();
    //         })
    //         .then((actualData) => {
    //             setWorkflowData(actualData);
    //             setError(null);
    //         })
    //         .catch((err) => {
    //             setError('Errors: ' + err.message);
    //         })
    //         .finally(() => {
    //             setLoading(false);
    //         });
    // }, []);

    const [typeOfWorkflow, setTypeOfWorkflow] = useState('eng-wf-001');
    const [startDate, setStartDate] = useState(new Date());
    const [closeoutDate, setcloseoutDate] = useState(new Date());
    const isReadOnly = false;



    const form = {
        startDate: startDate,
        closeoutDate: closeoutDate,
    }


    // submitSection = () => {
    // fetch('http://10.0.9.56:8080/create', {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({
    //         name: form.epop,
    //         status: "Engineering Configuration Management",
    //     }),
    // }).then(window.location.reload())
    //     .catch((err) => console.log('error'))
    // }

    function testing(e) {
        setTypeOfWorkflow(e.target.value);
    }

    function handleSubmit(e) {
        e.preventDefault();
        this.submitSection();
    };

    function setHold(e) {
        console.log("To be held...");
    }

    function setReject(e) {
        console.log("To be Rejected...");
    }

    return (
        <>
            <h2><Link to={`/dashboard`}>Back...</Link></h2>
            <Card className='rounded'>
                <Card.Body>
                    <Card.Text as='div' className='text-black'>
                        This section will contain methods to manage career opportunities that are displayed in the "Career" section above.
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    )
}

export default CareersManagement